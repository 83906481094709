@font-face {
  font-family: 'Gotham Narrow';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/gotham-narrow/400/GothamNarrow-Book.eot');
  src: url('fonts/gotham-narrow/400/GothamNarrow-Book.woff2') format('woff2'),
  url('fonts/gotham-narrow/400/GothamNarrow-Book.woff') format('woff'),
  url('fonts/gotham-narrow/400/GothamNarrow-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Narrow';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/gotham-narrow/600/GothamNarrow-Medium.eot');
  src: url('fonts/gotham-narrow/600/GothamNarrow-Medium.woff2') format('woff2'),
  url('fonts/gotham-narrow/600/GothamNarrow-Medium.woff') format('woff'),
  url('fonts/gotham-narrow/600/GothamNarrow-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Narrow';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/gotham-narrow/800/GothamNarrow-Bold.eot');
  src: url('fonts/gotham-narrow/800/GothamNarrow-Bold.woff2') format('woff2'),
  url('fonts/gotham-narrow/800/GothamNarrow-Bold.woff') format('woff'),
  url('fonts/gotham-narrow/800/GothamNarrow-Bold.ttf') format('truetype');
}
