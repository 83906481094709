.floating-footer {
  position: fixed;
  bottom: 0px; // Distance from bottom
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between; // Center the buttons
  padding: 10px;
  background-color: white; // Your desired background color
  box-shadow: 0px -5px 14px 0px #41474A26;

  z-index: 999; // Ensure it's above other content
}

