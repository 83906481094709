.custom-button {
  height: 64px;
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 10px;
  border: none;
  align-items: center;
  display: flex;

  &:hover {
    box-shadow: 0px 6px 12px 0px #0000002E;
  }

  &:active {
    box-shadow: none;
  }

  &.surface {
    border: 1px solid #C6C8C9;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);

    &:hover {
      background: linear-gradient(0deg, #E9EBEB, #E9EBEB),
      linear-gradient(0deg, #C6C8C9, #C6C8C9);
      border: 1px solid #C6C8C9;
    }

    &:active {
      background: linear-gradient(0deg, #E9EBEB, #E9EBEB),
      linear-gradient(0deg, #C6C8C9, #C6C8C9);
      border: 1px solid #C6C8C9;
    }

    .icon {
      color: #7A7E80;
    }
  }

  &.secondary {
    background: #EC6608; //todo check from palette
    border: none;
    color: $white;

    &:hover {
      background: #CF4A0C;
      border: 1px solid #C6C8C9;
    }

    &:active {
      background: #C13601;
      border: 1px solid #C6C8C9;
    }

  }

  &.tertiary {
    background: #35B7BD;
    border: none;
    color: $white;

    &:hover {
      background: #2E9091;
      border: 1px solid #C6C8C9;
    }

    &:active {
      background: #218386;
      border: 1px solid #C6C8C9;
    }
  }

  &.primary {
    background: #000000;
    border: none;
    color: $white;

    &:hover {
      background: #1C2022;
      border: 1px solid #C6C8C9;
    }

    &:active {
      background: #7A7E80;
      border: 1px solid #C6C8C9;
    }

    .icon {
      color: #EC6608;
    }
  }

  &.inactive {
    background: #E9EBEB;
    color: #C6C8C9;

    &:hover {
      background: #E9EBEB;
      color: #C6C8C9;
      box-shadow: none;
    }

    &:active {
      background: #E9EBEB;
      color: #C6C8C9;
      box-shadow: none;
    }
  }

  &.large {
    min-width: 128px;
    height: 64px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;

  }

  &.normal {
    min-width: 96px;
    height: 48px;
    font-size: 16px;
    font-weight: 325;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: center;

  }
}
