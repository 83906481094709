.stepper {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  .line {
    width: 100%;
    height: 8px;
    background-color: #E9EBEB;
    position: absolute;
    border-radius: 5px;
  }

  .steps {
    display: flex;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .step {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    .circle {
      width: 5px;
      height: 5px;
      background-color: #FFFFFF; // Assuming $white is #FFFFFF
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      right: 1.5px;
      top: 1.5px;
      transition: background-color 0.3s ease-in-out;
    }

    .label {
      position: absolute;
      top: 8px;
      line-height: 12px;
      font-size: 10px;
      white-space: nowrap;
      transform: translateX(-50%);
      left: calc(100% - 3px - 4px);
      transition: color 0.3s ease-in-out;
    }

    &:last-child .label {
      left: auto;
      right: 5px;
      transform: translateX(0);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 8px;
      width: 100%;
      background-color: transparent;
      z-index: 1;
    }

    &:not(.active)::before {
      background-color: transparent;
    }

    &.active {
      color: $secondary; // Define $secondary color
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

    &.active::before {
      background-color: $secondary;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

    &.all {
      color: $success; // Define $success color
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

    &.all::before {
      background-color: $success;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

    &.active-last::before {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:first-child::before {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child.active::before {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  // Media query for smaller screens
  @media (max-width: 600px) {
    .step {
      .circle {
        width: 3px;
        height: 3px;
      }

      .label {
        font-size: 8px;
        top: 6px;
      }

      &:last-child .label {
        right: 3px; // Adjust for smaller screen
      }
    }
  }
}
