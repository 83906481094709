/* You can add global styles to this file, and also import other style files */
@import "styles/themes/custom-theme";
@import 'styles/colors';
@import 'styles/media';
@import 'styles/fonts';

@import "styles/components/stepper.component";
@import "styles/components/footer.component";
@import "styles/components/dropdown.component";
@import "styles/components/flow-steps.component";
@import "styles/components/button.component";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

html, body {
  height: 100%;
}

.mat-typography {
  margin: 0;
  font-family: 'Gotham Narrow', sans-serif !important;
  color: $black;

  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;


  h1 {
    font-family: 'Gotham Narrow', sans-serif !important;
    font-size: 56px;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
  }

  h2 {
    font-family: 'Gotham Narrow', sans-serif !important;
    font-size: 44px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
  }

  h3 {
    font-family: 'Gotham Narrow', sans-serif !important;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.005em;
    text-align: left;
    margin: 0;
  }

  h4 {
    font-family: 'Gotham Narrow', sans-serif !important;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
    margin: 0;
  }

  h5 {
    font-family: 'Gotham Narrow', sans-serif !important;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: left;
    margin: 0;
  }

  h6 {
    font-family: 'Gotham Narrow', sans-serif !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    margin: 0;
  }

  b {
    font-weight: 800;
  }
}
