$white: #FFFFFF;
$black: #000000;

$primary: #41474A;
$primary-variant: $black;
$secondary: #EC6608;
$secondary-variant: #CF4A0C;

$background: $white;
$surface: $white;
$error: #E74110;
$on-primary: $white;
$on-secondary: $black;
$on-background: $black;
$on-surface: $black;
$on-error: $white;

$success: #26D36B;
$success-variant: #44A569;


