.custom-select {
  position: relative;
  width: 100%; // Adjust the width as needed

  .select-box {
    padding: 0 10px;
    border: 1px solid #ccc; // Default border color
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    transition: border-color 0.3s ease; // Transition for border color
    height: 48px;

    .dropdown-label {
      position: absolute;
      top: -8px; // Adjust as needed
      left: 10px;
      line-height: 16px;
      background-color: white;
      padding: 0 5px;
      transition: opacity 0.3s ease;
      font-size: xx-small;
    }

    &.expanded {
      border-color: $secondary;
    }

    mat-icon {
      font-size: 24px;
      transition: transform 0.3s ease;
    }
  }

  .options-container {
    position: absolute;
    top: calc(100% + 5px); // Small gap below the select box
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 560px;
    overflow-y: auto;
    border-radius: 4px;
    opacity: 0;
    transform: translateY(-10px);
    transition: max-height 0.9s ease, opacity 0.3s ease, visibility 0.3s ease;
    visibility: hidden;
    box-sizing: border-box;
    z-index: 1000;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #C6C8C9;
    ;
      border: none;
    }

    &::-webkit-scrollbar-track {
      background-color: #E9EBEB;
      border-radius: 0;
      border: none;
    }

    .option {
      //height: 48px;
      padding: 6px 8px 6px 8px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px solid #E9EBEB; // Bottom border for all but the last option
      }

      &:hover {
        background-color: #f0f0f0;
      }

      &.selected {
        color: $secondary;
        border-left: 2px solid $secondary;
        padding-left: 6px;
      }
    }
  }

  &.expanded .options-container {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  @media (max-width: 600px) {
    width: 100%; // Full width on smaller screens
  }
}
